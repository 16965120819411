.header {
    text-align: center;
}

.toast {
    margin: 0 auto;
}

.customBtnToolbar {
    display: inline !important;
}

.customBtnToolbar > .btn {
    margin-right: 1rem;
}
